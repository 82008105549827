import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DetailsUiComponent,
  SummaryUiComponent,
  TableUiComponent,
} from '@my-toolbox-fe/ui-elements';
import { ROLE_EXTERNAL, ROLE_INTERNAL, RolesDirective } from '@my-toolbox-fe/feat-roles';

@Component({
  selector: 'mt-faq',
  standalone: true,
  imports: [CommonModule, DetailsUiComponent, TableUiComponent, RolesDirective],
  templateUrl: './faq.ui-component.html',
  styleUrl: './faq.ui-component.scss',
})
export class FaqUiComponent {
  internalAndExternalRoles = [ROLE_EXTERNAL, ROLE_INTERNAL];
  internalRoles = [ROLE_INTERNAL];
  externalRoles = [ROLE_EXTERNAL];

  visiblitiesColumns = [
    { key: 'visibility', label: 'Visibility' },
    { key: 'description', label: 'Who can see the document' },
  ];

  visiblitiesRows = [
    {
      visibility: 'Private',
      description: 'Internal users excluding AC Rental employees',
    },
    {
      visibility: 'Internal',
      description: 'Internal users including AC Rental employees',
    },
    {
      visibility: 'External',
      description:
        'All Internal users and External users (Dealers, end customers, ... that have a MyPT account)',
    },
  ];
}
